<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  .el-form-item {
    margin-bottom: 0;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import CertHeader from "./cert-header";
  import {
    getCert,
    editCert,
    deleteCert
  } from "@/api/zhengshu/record.js"

  /**
   * 证书库检查
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      CertHeader,

    },
    data() {
      return {
        title: "证书库导入 ",
        title2: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          zskid: "",
          keyName: "",
          keyWord: "",
          yxyj: ''
        },
        zskid: "",
        addForm: {},
        tableList: [],
        keyName: [{
            name: "姓名",
            val: "ksxm",
          },
          {
            name: "证书号",
            val: "zsglh",
          },
          {
            name: "身份证号",
            val: "zjhm",
          }
        ],
        showModal: false,
        // 证书状态
        statusLsit: [{
            name: "已查询",
            val: "1",
          },
          {
            name: "未查询",
            val: "0",
          },
        ],
        getWay: [{
          name: "重置为初始状态",
          val: ""
        },{
            name: "邮寄",
            val: "1"
          },
          {
            name: "自领取",
            val: "2"
          },
        ],
      };
    },
    methods: {
      // 准考证分页
      getList() {
        getCert(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      editItem(e) {
        this.addForm = e;
        this.showModal = true;
      },
      submit() {
        this.$confirm('确认修改吗, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let formDate = JSON.parse(JSON.stringify(this.addForm))
          editCert(formDate).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '修改成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });
        });
      },
      // 删除数据
      deleteItem(zsid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteCert(zsid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
    },
    mounted() {
      this.getList()
    },
    created() {
      this.zskid = this.$route.query["zskid"] ? this.$route.query["zskid"] : "";
      this.pageData.zskid = this.zskid;
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="title2" :items="items" />
    <CertHeader ref="header" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <el-select class=" mr-2 " style="width:8%;" clearable placeholder="查询类别" v-model="pageData.keyName"
                  size="small">
                  <el-option v-for="(item, i) in keyName" :label="item.name" :value="item.val" :key="i">
                  </el-option>
                </el-select>
                <input placeholder="关键字" class="h30 w-10 form-control border-blue mr-2" maxlength="50"
                  v-model="pageData.keyWord" />
                <el-select class=" mr-2 " style="width:8%;" clearable placeholder="是否允许邮寄" v-model="pageData.yxyj"
                  size="small">
                  <el-option label="允许" value="1"></el-option>
                  <el-option label="不允许" value="0"></el-option>
                </el-select>
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <!-- <b-button variant="outline-info" class="flexList w-md mr-2 condition"
                  @click="$refs.cjConditionSearch.show(conditionList)"><i
                    class="iconfont icon-fasfa-chevron-circle-down mr-2"></i>更多条件</b-button> -->
              </div>

            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;">序号</th>
                    <th style="width: 5%;">姓名</th>
                    <th style="width: 9%;">证件号</th>
                    <th style="width: 3%;">性别</th>
                    <th style="width: 6%;">出生日期</th>
                    <th style="width: 6%;">出生年月</th>
                    <th style="width: 9%;">档案号</th>
                    <th style="width: 6%;">证书编号</th>
                    <th style="width: 5%;">专业通过</th>
                    <th style="width: 5%;">报考级别</th>
                    <th style="width: 8%;">报考专业</th>
                    <th style="width: 8%;">考区名称</th>
                    <th style="width: 8%;">报名地市名称</th>
                    <th style="width: 5%;">自定义字段</th>
                    <th style="width: 5%;">查询状态</th>
                    <th style="width: 5%;">允许邮寄</th>
                    <th style="width: 5%;">操作</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td><a href="" class="blue-font">{{obj.ksxm}}</a></td>
                    <td>{{obj.zjhm}}</td>
                    <td>{{obj.xb}}</td>
                    <td>{{obj.csrq}}</td>
                    <td>{{obj.csny}}</td>
                    <td>{{obj.dah}}</td>
                    <td>{{obj.zsglh}}</td>
                    <td>{{obj.zytg}}</td>
                    <td>{{obj.bkjbmc}}</td>
                    <td>{{obj.bkzymc}}</td>
                    <td>{{obj.kqmc}}</td>
                    <td>{{obj.bmdsmc}}</td>
                    <td>
                      <el-popover placement="top-start" title="自定义字段" width="200" trigger="hover" :content="obj.json">
                        <a slot="reference">其他信息</a>
                      </el-popover>
                    </td>
                    <td>{{obj.cxzt==1?'已查询':'未查询'}}</td>
                    <td>
                      <a href="javascript:;">{{obj.yxyj?'允许':''}}</a>
                    </td>
                    <td class="tab-icon ">
                      <i class="iconfont icon-edit-two mr-1" @click="editItem(obj)"></i>
                      <i class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj.zsid,obj.ksxm)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
            <div style="clear: both;height: 60px;">
              <!-- <button class="btn btn-info h35 mr-2 sub-btn" @click="editZkz">确认成绩库发布</button> -->
              <a href="javascript:history.back()"><button class="btn btn-secondary h35 mr-2 ">返回上一步</button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->
    <b-modal id="certificate-modal" v-model="showModal" title="修改证书信息" centered title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>出生年月：</label>
              <div class=" col-sm-10 p-0  line1">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.csny" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>证件号码：</label>
              <div class=" col-sm-10 p-0  line2">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.zjhm" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>考生姓名：</label>
              <div class=" col-sm-10 p-0  line2">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.ksxm" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>报考级别：</label>
              <div class=" col-sm-10 p-0  line2">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.bkjbmc" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>报考专业：</label>
              <div class=" col-sm-10 p-0  line2">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.bkzymc" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>专业通过：</label>
              <div class=" col-sm-10 p-0  line2">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.zytg" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>证书编号：</label>
              <div class=" col-sm-10 p-0  line2">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.zsglh" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>&#12288;&#12288;性别：</label>
              <div class=" col-sm-10 p-0  line2">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.xb" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>出生日期：</label>
              <div class=" col-sm-10 p-0  line2">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.csrq" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>&#12288;档案号：</label>
              <div class=" col-sm-10 p-0  line2">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.dah" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>考区名称：</label>
              <div class=" col-sm-10 p-0  line2">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.kqmc" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>报名地市：</label>
              <div class=" col-sm-10 p-0  line2">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.bmdsmc" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>其他信息：</label>
              <div class=" col-sm-10 p-0  line2">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.json" placeholder="请输入考试名称"
                  class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>查询状态：</label>
              <div class=" col-sm-10 p-0  line2">
                <select name="" class="form-control form-select pt-1" v-model="addForm.cxzt">
                  <option value="" disabled>请选择查询状态</option>
                  <option :value="item.val" v-for="(item,i) in statusLsit" :key="i">
                    {{item.name}}
                  </option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>领取方式：</label>
              <div class=" col-sm-10 p-0  line2">
                <select name="" class="form-control form-select pt-1" v-model="addForm.lqfs">
                  <option value="" disabled>请选择领取方式</option>
                  <option :value="item.val" v-for="(item,i) in getWay" :key="i">
                    {{item.name}}
                  </option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>允许邮寄：</label>
              <div class=" col-sm-10 p-0  line2">
                <b-form-checkbox v-model="addForm.yxyj" disabled switch class="mt-1 switch-check" :value="true"
                  :unchecked-value="false">
                </b-form-checkbox>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>操作提示：</label>
              <div class=" col-sm-10 p-0  line2 text-danger">
                以上信息仅当点击确定按钮并确认修改后生效！
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3   text-center">
        <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
            @click="submit">确定</button>
          <button type="button" class="btn btn-secondary h30  w-md" @click="showModal = false">取消</button>
        </div>
      </div>
    </b-modal>
    <!-- 弹窗结束 -->
  </Layout>
</template>
